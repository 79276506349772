<template>
    <div>
        <modal :name="modal_name" class="final-modal popup-modal-all-width-height" :class="{'modalZindex':safariTrue,'modal-fix-width':loggedInUser.role == 'customer','select-addons-modal':loggedInUser.role != 'customer'}" transition="nice-modal-fade">
            <div class="v-modal-content">
                <div class="v-modal-header">
                    <span class="v-modal-dialog-title v-modal-title">{{comboDetails.name.replace('_','')}}</span>
                    <span @click="hide()">
                        <i class="icon icon-close pointer text-white"></i>
                    </span>
                </div>
                <div class="general-section" style="padding: 15px 0px;">
                <div class="v-modal-body">
                    <div class="">
                        <div v-for="(pl,index) in product_list" :key="index">
                            <div class="infoHeader">
                                <div class="descriptionheader">
                                    <div class="description-text-header-left">
                                        <h5>
                                            <span class="font-weight-bold text-secondary fs-16 whiteSpace">Product {{index + 1}}</span>
                                        </h5>
                                    </div>
                                    <div class="description-text-header-right">
                                        <span @click="expandProduct(index)" class="collapse-addon">
                                            <img src="https://cdn.digicollect.com/cdn/pos/images-new-theme/addon-collapse.svg" class="w-30 rotateIcon" :class="{'iconRotate':!selected_product.includes(index+'new')}">
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div v-if="selected_product.includes(index+'new')">
                                <div v-for="(alternative,ai) in pl" :key="ai" class="pb-3">
                                    <div class="infoHeader">
                                        <div class="descriptionheader">
                                            <div class="description-text-header-left">
                                                <h5>
                                                    <span class="font-weight-bold text-secondary fs-16 whiteSpace">Alternative {{ai + 1}} ( {{alternative.selected_quantity}} /{{alternative.alternative_max_quantity}})</span>
                                                </h5>
                                            </div>
                                            <div class="description-text-header-right-small" style="margin-right: 5px;">
                                                <span class="collapse-addon" @click="alterNativeShow(alternative.show,index,ai)">
                                                    <img src="https://cdn.digicollect.com/cdn/pos/images-new-theme/arrow-down.svg"  style="width:25px;right: -2px!important;" v-if="alternative.show">
                                                    <img src="https://cdn.digicollect.com/cdn/pos/images-new-theme/main-collapse.svg" style="width:25px;right: -2px!important;" v-else>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="d-flex flex-wrap" v-if="alternative.show">
                                        <div v-if="alternative.product_list.length == 0">
                                            <span class="font-weight-bold">No Product Available</span>
                                        </div>
                                        <div class="addon-main mt-4 ml-2 mb-10" v-for="(product,pindex) in alternative.product_list" :key="pindex">
                                            <div class="variant-addon" :class="{'selected_variant':selected_product_ids.includes(product._id) && product.selected_quantity > 0}">
                                                <div @click="selectProduct(product,alternative,index)" :class="{'PointerEventsNone':!product.is_available || alternative.enabled}">
                                                    <avatar
                                                        class="product-avatar product-span content-center pt-7"
                                                        v-if="product.image == ''"
                                                        :username="product.product_name.replace(/ .*/,'')"
                                                        :src="product.image"
                                                        :size="50"
                                                        :rounded="false"
                                                        color="#f5a623"
                                                        background-color="transparent">
                                                    </avatar>
                                                    <img :src="product.image" class="avatar avatar-addons" :alt="product.product_name" v-else>
                                                    <p class="unavailable-addons" v-if="product.is_available == 0">Unavailable</p>
                                                </div>
                                                <div v-if="selected_product_ids.includes(product._id) && product.selected_quantity > 0"  class="input-prepend-append bg-orange addon-quantity d-flex pl-1 pr-1">
                                                    <a class="btn-prepend pt-1 pointer" @click="decreaseQty(product,alternative,index)">
                                                        <img src="https://cdn.digicollect.com/cdn/pos/images-new-theme/minus-addon.svg" style="width:25px;transform: rotate(90deg);">
                                                    </a>
                                                    <input readonly v-model.number="product.selected_quantity" onkeypress="return (event.charCode == 8 || event.charCode == 0 || event.charCode == 13) ? null : event.charCode >= 48 && event.charCode <= 57" type="number" min="0" max="1000" class="form-control bg-orange w-52 text-center">
                                                    <a class="btn-append pt-1 pointer" @click="increaseQty(product,alternative,index)">
                                                        <img src="https://cdn.digicollect.com/cdn/pos/images-new-theme/plus-addon.svg" style="width:25px">
                                                    </a>
                                                </div>
                                            </div>
                                            <span class="d-flex justify-content-start mt-4">
                                                <div>
                                                    <el-tooltip class="item" effect="dark" content="Non-Veg" placement="top" v-if="product.product_type == 'Non-Veg'">
                                                        <img  src="https://cdn.digicollect.com/cdn/pos/images-new-theme/non-veg.svg">
                                                    </el-tooltip>
                                                    <el-tooltip class="item" effect="dark" content="Veg" placement="top" v-else-if="product.product_type == 'Veg'">
                                                        <img  src="https://cdn.digicollect.com/cdn/pos/images-new-theme/veg.svg">
                                                    </el-tooltip>
                                                    <el-tooltip class="item" effect="dark" content="Egg" placement="top" v-else-if="product.product_type == 'Egg'" >
                                                        <img src="https://cdn.digicollect.com/cdn/pos/images-new-theme/egg.svg">
                                                    </el-tooltip>
                                                    <el-tooltip class="item" effect="dark" content="Pescatarian" placement="top" v-else-if="product.product_type == 'Pescatarian'">
                                                        <img  style="width:16px;" src="https://cdn.digicollect.com/cdn/pos/images-new-theme/psec.svg">
                                                    </el-tooltip>
                                                </div>
                                                <div class="col addon-col">
                                                    <span class="word-break text-left mt-3 font-weight-bold">{{product.product_name}}</span>
                                                </div>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <span class="collapse-addon mt-2" @click="expandNotes()">
                            <img src="https://cdn.digicollect.com/cdn/pos/images-new-theme/addon-collapse.svg" :class="{'iconRotate':showNotes == true}" class="w-30 rotateIcon">
                        </span>
                        <span class="d-flex text-secondary font-weight-bold fs-16 mt-2">Notes</span>
                        <hr class="ml-15 addon-border mr-8">
                        <textarea v-if="showNotes == true" class="form-control addon-note mt-2" v-model="note"></textarea>
                        <div class="total-addon row no-gutters align-items-center p-2 mt-4">
                            <div>
                                <avatar
                                    class="product-avatar content-center pt-7"
                                    v-if="comboDetails.image_url == ''"
                                    :username="comboDetails.name.replace(/ .*/,'')"
                                    :src="comboDetails.image_url"
                                    :size="50"
                                    :rounded="false"
                                    color="#f5a623"
                                    background-color="transparent">
                                </avatar>
                                <img v-else class="b-r-7" :src="comboDetails.image_url">
                            </div>
                            <div class="col pl-2 pr-2">
                                <div class="d-flex justify-content-between w-100">
                                    <div class="d-flex">
                                        <span class="text-white font-weight-bold">{{comboDetails.name}}</span>
                                    </div>
                                    <span class="text-white font-weight-bold text-uppercase">Combo Price: {{total}}</span>
                                </div>
                                <hr style="border:0.7px solid rgb(132, 136, 140);">
                                <div class="d-flex justify-content-between">
                                    <div class="input-prepend-append d-flex pl-1 pr-1 align-items-center">
                                        <a  class="btn-prepend pt-1 pointer" @click="decreaseProductQty()" :disabled="comboDetails.selected_quantity < 1">
                                            <img src="https://cdn.digicollect.com/cdn/pos/images-new-theme/qty-minus.svg" style="width:25px;">
                                        </a>
                                        <input @input="productQty()" oncopy="return false" onpaste="return false" v-validate="{required:true}" name="item_qty" v-model.number="comboDetails.selected_quantity" onkeypress="return (event.charCode == 8 || event.charCode == 0 || event.charCode == 13) ? null : event.charCode >= 48 && event.charCode <= 57" type="number" min="1" :max="comboDetails.product_quantity" class="form-control text-center add-custom-addon">
                                        <a class="btn-append pt-1 pointer" @click="increaseProductQty()">
                                            <img src="https://cdn.digicollect.com/cdn/pos/images-new-theme/plus-qty.svg" style="width:25px">
                                        </a>
                                    </div>
                                    <div class="save-addon d-flex align-items-center">
                                        <el-tooltip class="item" effect="dark" content="Delete" placement="top">
                                            <span class="ml-3 pointer" @click="deselectAll()">
                                                <img src="https://cdn.digicollect.com/cdn/pos/images-new-theme/trash_addon.svg">
                                            </span>
                                        </el-tooltip>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                </div>
                <div class="v-modal-dialog-actions modal-bg text-center d-block" :class="{'pt-5':loggedInUser.role != 'customer','pt-0':loggedInUser.role == 'customer'}">
                    <div class="d-flex mt-3">
                        <button @click="fastPayProduct()" v-if="!editOrder && loggedInUser.role != 'customer' && !hold_status == true" class="pointer d-flex justify-content-between align-items-center bg-green-image pl-2 pr-2 pt-1 pb-1 b-r-7 w-100">
                            <span class="text-white font-weight-bold d-flex align-items-center fs-16">Fast Pay</span>
                            <span>
                                <img src="https://cdn.digicollect.com/cdn/pos/images-new-theme/fast-pay.svg">
                            </span>
                        </button>
                        <button :class="{'ml-3':!editOrder}" v-if="loggedInUser.role != 'customer'" @click="fastCheckoutProduct()" class="pointer d-flex justify-content-between bg-blue-image align-items-center pl-2 pr-2 pt-1 pb-1 b-r-7 w-100">
                            <span class="fs-16 text-white font-weight-bold d-flex align-items-center">Fast Checkout</span>
                            <span>
                                <img src="https://cdn.digicollect.com/cdn/pos/images-new-theme/fast-checkout.svg">
                            </span>
                        </button>
                        <button :disabled="savedProduct" @click="saveProduct()" v-if="editOrder" class="pointer d-flex justify-content-between bg-blue-image align-items-center pl-2 pr-2 b-r-7 w-100 bg-orange-image" :class="{'pt-1':loggedInUser.role != 'customer','pt-3':loggedInUser.role == 'customer','pb-1':loggedInUser.role != 'customer','pb-3':loggedInUser.role == 'customer','ml-3':loggedInUser.role != 'customer'}">
                            <span class="font-weight-bold fs-16 text-black">Save</span>
                            <span>
                                <img src="https://cdn.digicollect.com/cdn/pos/images-new-theme/save-arrow.svg">
                            </span>
                        </button>
                    </div>
                    <button :disabled="savedProduct" @click="saveProduct()" v-if="!editOrder" class="pointer w-100 d-flex justify-content-between align-items-center bg-blue-image p-3 bg-orange-image mt-3 b-r-7">
                        <span class="font-weight-bold fs-16 text-black">Save</span>
                        <span>
                            <img src="https://cdn.digicollect.com/cdn/pos/images-new-theme/save-arrow.svg">
                        </span>
                    </button>
                </div>
            </div>
        </modal>
        <combo-variant modal_name="combo-variant" v-if="isVariant" :productDetails="productDetails" :alternateIndex="alternateIndex" @showSelectCombo="showSelectCombo()" @variantUpdate="variantUpdate"></combo-variant>
        <select-variant modal_name="select-variant" :variantDetails="variantDetails" @UpdateBasedOnMultiple="UpdateBasedOnMultiple"></select-variant>
        <payment-method v-if="payshow" modal_name="method_payment_product" :is_credit_card_used="false" :summary="summary" :grand_total_value="grand_total_value" :grand_total_amount="grand_total_amount" :fastPay="true" @close="closePaymentMethod"></payment-method>
        <sweet-modal ref="success_modal" icon="success">
            {{ modal_msg }}
          </sweet-modal>
          <sweet-modal ref="warning_modal" icon="warning">
            {{ modal_msg }}
          </sweet-modal>
    </div>
</template>
<script>
import { SweetModal } from "sweet-modal-vue";
import Avatar from 'vue-avatar'
const ComboVariant = () => import('./ComboVariant');
const PaymentMethod = () => import('./PaymentMethod');
const SelectVariant = () => import('./SelectVariant');
export default {
    props:['modal_name','comboDetails','editOrder','selectedCourse','order_index','hold_status'],
    components:{
        ComboVariant,
        SweetModal,
        Avatar,
        PaymentMethod,
        SelectVariant
    },
    data(){
        return{
            safariTrue:false,
            selected_product:[],
            product_list:[],
            selected_product_ids:[],
            combo_products:[],
            total:0,
            isVariant:false,
            payshow:false,
            productDetails:'',
            variantDetails:'',
            grand_total_value:0,
            grand_total_amount:0,
            modal_msg:'',
            summary:'',
            note:'',
            showNotes:true,
            savedProduct:false,
            alternateIndex:0
        }
    },
    methods:{
        // HandelClose
        expandAndCloseProduct(value){
            let newValue = value +'new'
            if (this.selected_product.find(x => x === newValue)) {
                this.selected_product = this.selected_product.filter(function(x) {
                    if (x !== newValue) {
                        return x
                    }
                })
            }else{
                this.selected_product.push(newValue)
            }
        },
        // Product Show and Hide
        expandProduct(value){
            let newValue = value +'new'
            if (this.selected_product.find(x => x === newValue)) {
                this.selected_product = this.selected_product.filter(function(x) {
                    if (x !== newValue) {
                        return x
                    }
                })
            }else{
                this.selected_product.push(newValue)
            }
        },
        // Notes Show and Hide
        expandNotes(){
            if(!this.showNotes){
                this.showNotes = true;
            }
            else{
                this.showNotes = false;
            }
        },
        // Modal Close
        hide(){
            this.product_list = []
            this.selected_product = []
            this.selected_product_ids = []
            this.combo_products = []
            this.$emit('cSelectCombo')
            this.$modal.hide(this.modal_name);
        },
        // Get all Product for Combo
        async getProductForCombo(params) {
            return new Promise(async (resolve, reject) => {
                let response = await this.$http.post('combos/get_product_list_for_combo', params)
                if (response.data.status_id == 1) {
                    resolve(response.data)
                } else {
                    reject(response.data.reason)
                    this.$toasted.global.error(response.data.reason);
                }
            })
        },
        async getDetails(){
            try {
                let params ={
                    _id:this.comboDetails._id
                }
                let res = await this.getProductForCombo(params)
                this.product_list = res.products_list
                this.product_list.forEach((el,i)=>{
                    el.forEach((el1,eli)=>{
                        el1['selected_quantity'] = 0
                        el1['show'] = true
                        el1['enabled'] = false
                        el1.product_list.forEach((newel,index)=>{
                            newel['selected_quantity'] = 0
                            if(newel.image.startsWith("data:image/png;base64")){
                                newel.image = ''
                            }else{
                                newel.image = newel.image
                            }
                        })
                    })
                })
                this.product_list.forEach((el,i)=>{
                    el.forEach((el1,eli)=>{
                        this.combo_products.push([])
                    })
                })
                this.product_list.forEach((el,i)=>{
                    let newValue = i +'new'
                    this.selected_product.push(newValue)
                })
            } catch (reason) {

            }
        },
        // Select Product
        selectProduct(product,alternative,pindex){
            if(product.hasMultipleVariants){
                if(Number.isNaN(product.selected_quantity)){
                    product.selected_quantity = 0
                }
                if(product.selected_quantity == 0){
                    if(alternative.selected_quantity < alternative.alternative_max_quantity){
                        this.alternateIndex = pindex
                        this.isVariant = true
                        this.productDetails = product
                        this.productDetails.variants.forEach((el,index)=>{
                            el['quantity'] = 0
                            el['alternative_id']= alternative.alternative_id
                        })
                        this.$modal.hide(this.modal_name);
                        setTimeout(() => {
                            this.$modal.show('combo-variant');
                        }, 200);
                    }
                }else{
                    alternative.selected_quantity = alternative.selected_quantity - product.selected_quantity
                    this.combo_products[pindex].forEach((el,i)=>{
                        if(el.product_id == product._id){
                            product.selected_quantity = 0
                            this.combo_products[pindex].splice(i,1)
                        }
                    })
                    alternative.product_list.forEach((el,i)=>{
                        if(el._id == product._id){
                            el.selected_quantity = product.selected_quantity
                            el.variants.forEach((v,vi)=>{
                                v.quantity = 0
                            })
                        }
                    })
                    setTimeout(() => {
                        this.expandAndCloseProduct(pindex)
                        setTimeout(() => {
                            this.expandAndCloseProduct(pindex)
                        }, 20);
                    }, 10);
                }
            }else{
                if (this.selected_product_ids.find(x => x === product._id)) {
                    if(alternative.selected_quantity > 0){
                        for(var i= 0;i<product.selected_quantity;i++){
                            alternative.selected_quantity--
                        }
                    }
                    this.combo_products[pindex].forEach((el,i)=>{
                        if(el.alternative_id == alternative.alternative_id){
                            this.selected_product_ids = this.selected_product_ids.filter(function(x) {
                                if (x !== product._id) {
                                    return x
                                }
                            })
                            this.product_list[pindex].forEach((p,index)=>{
                                if(p.alternative_id != alternative.alternative_id){
                                    if(alternative.selected_quantity == 0){
                                        p.enabled = false
                                    }
                                }
                            })
                            if(el.variant_id == product.variant_id){
                                product.selected_quantity = 0
                                this.combo_products[pindex].splice(i,1)
                            }
                        }
                    })
                    this.expandAndCloseProduct(pindex)
                    setTimeout(() => {
                        this.expandAndCloseProduct(pindex)
                    }, 20);
                }else{
                    if(alternative.selected_quantity < alternative.alternative_max_quantity){
                        this.product_list[pindex].forEach((p,index)=>{
                            if(p.alternative_id != alternative.alternative_id){
                                p.enabled = true
                            }
                        })
                        this.selected_product_ids.push(product._id)
                        product.selected_quantity++
                        alternative.selected_quantity++
                        let params ={
                            variant_id:product.variant_id,
                            product_id:product._id,
                            quantity:product.selected_quantity,
                            alternative_id:alternative.alternative_id
                        }
                        this.combo_products[pindex].push(params)
                    }
                }
            }
        },
        // Increase Qty of Product
        increaseQty(product,alternative,pindex){
            if(alternative.selected_quantity < alternative.alternative_max_quantity){
                if(product.hasMultipleVariants){
                    if(product.selected_quantity == 0){
                        this.alternateIndex = pindex
                        this.isVariant = true
                        this.productDetails = product
                        this.productDetails.variants.forEach((el,index)=>{
                            el['alternative_id']= alternative.alternative_id
                        })
                        this.$modal.hide(this.modal_name);
                        setTimeout(() => {
                            this.$modal.show('combo-variant');
                        }, 200);
                        alternative.selected_quantity++
                    }else{
                        let params = {
                            product_id:product._id,
                            selected_quantity:product.selected_quantity,
                            product_name:product.product_name,
                            variants:product.variants,
                            alternative_max_quantity:alternative.alternative_max_quantity,
                            selected_quantity:alternative.selected_quantity,
                            ai:pindex,
                            alternative_id:alternative.alternative_id
                        }
                        this.variantDetails = params
                        this.$modal.hide(this.modal_name);
                        setTimeout(() => {
                            this.$modal.show('select-variant');
                        },200);
                    }
                }else{
                    if(product.selected_quantity < product.variant_quantity){
                        product.selected_quantity++
                        this.combo_products[pindex].forEach((el,i)=>{
                            if(el.product_id == product._id){
                                el.quantity = product.selected_quantity
                            }
                        })
                        this.product_list[pindex].forEach((el,i)=>{
                            if(el.alternative_id == alternative.alternative_id){
                                el.product_list.forEach((p,i)=>{
                                    if(p._id == product._id){
                                        p.selected_quantity = product.selected_quantity
                                        this.$set(this.product_list, 'selected_quantity', p.selected_quantity)
                                    }
                                })
                            }
                        })
                        this.expandAndCloseProduct(pindex)
                        // setTimeout(() => {
                            this.expandAndCloseProduct(pindex)
                        // }, 20);
                        alternative.selected_quantity++
                    }else{
                        this.$toasted.global.error('Product Max Quantity Exceeded');
                    }
                }
            }else{
                this.$toasted.global.error('Alternative Max Quantity Exceeded');
            }
        },
        // Decrease Qty of Product
        decreaseQty(product,alternative,pindex){
            if(product.hasMultipleVariants){
                if(product.selected_quantity > 0){
                    let params = {
                        product_id:product._id,
                        selected_quantity:product.selected_quantity,
                        product_name:product.product_name,
                        variants:product.variants,
                        alternative_max_quantity:alternative.alternative_max_quantity,
                        selected_quantity:alternative.selected_quantity,
                        ai:pindex,
                        alternative_id:alternative.alternative_id
                    }
                    this.variantDetails = params
                    this.$modal.hide(this.modal_name);
                    setTimeout(() => {
                        this.$modal.show('select-variant');
                    },200);
                }
            }else{
                if(product.selected_quantity > 0){
                    product.selected_quantity--
                    this.combo_products[pindex].forEach((el,i)=>{
                        if(el.variant_id == product.variant_id){
                            el.quantity = product.selected_quantity
                        }
                    })
                    this.product_list[pindex].forEach((el,i)=>{
                        if(el.alternative_id == alternative.alternative_id){
                            el.product_list.forEach((p,i)=>{
                                if(p._id == product._id){
                                    p.selected_quantity = product.selected_quantity
                                    this.$set(this.product_list, 'selected_quantity', p.selected_quantity)
                                }
                            })
                        }
                    })
                    this.expandAndCloseProduct(pindex)
                    // setTimeout(() => {
                        this.expandAndCloseProduct(pindex)
                    // }, 20);
                    if(alternative.selected_quantity > 0){
                        alternative.selected_quantity--
                    }
                }else{
                    this.$toasted.global.error('Please Deselect the Product.If you wish to remove the Product');
                }
            }
        },
        // All Save Options
        // Fast Pay Option
        async fastPayProduct(){
            try {
                const result = []
                this.combo_products.forEach((el,i)=>{
                    if(el.length){
                        result.push(el)
                    }
                })
                let params = {
                    order_id:this.order_index,
                    product_id:this.comboDetails._id,
                    addons:[],
                    quantity:this.comboDetails.selected_quantity,
                    course:this.selectedCourse,
                    note:this.note,
                    is_customizable:true,
                    is_combo:true,
                    combo_products:result,
                    web:true,
                }
                let res = await this.saveProductApi(params)
                if(res.status_id == 1){
                    this.summary = res.response.order
                    this.fastpayProductSubmit()
                    this.$modal.hide(this.modal_name);
                }
            } catch (reason) {

            }
        },
        async fastpayProductSubmit(){
            try {
                this.$http.get("/cashier/is_cashier_session_active").then(res => {
                this.modal_msg = res.data.reason;
                    if (res.data.status_id == 1) {
                        this.$store.commit("setCashRegister",res.data.response.cash_register_details);
                        this.fastpayProductSubmitConfirm()
                    } else {
                        this.$toasted.global.error(res.data.reason);
                    }
                });
            } catch (reason) {
                this.$toasted.global.error(reason);
            }
        },
        fastpayProductSubmitConfirm(){
            this.payshow = true
            this.$http.post('orders/grand_total', { order_id: this.order_index }).then(response => {
                this.grand_total_value = response.data.value.toFixed(2)
                this.grand_total_amount = response.data.amount
                this.$modal.show('method_payment_product')
            })
        },
        closePaymentMethod(){
            this.payshow = false
            this.$modal.show(this.modal_name);
            this.$modal.hide('method_payment_product')
        },
        // Save Product
        async saveProduct(){
            try {
                const result = []
                this.combo_products.forEach((el,i)=>{
                    if(el.length){
                        result.push(el)
                    }
                })
                let params = {
                    order_id:this.order_index,
                    product_id:this.comboDetails._id,
                    addons:[],
                    quantity:this.comboDetails.selected_quantity,
                    course:this.selectedCourse,
                    note:this.note,
                    is_customizable:true,
                    is_combo:true,
                    combo_products:result,
                    web:true,
                }
                let res = await this.saveProductApi(params)
                if(res.status_id == 1){
                    this.$emit('cSelectCombo')
                    this.product_list = []
                    this.selected_product = []
                    this.selected_product_ids = []
                    this.combo_products = []
                    this.$modal.hide(this.modal_name);
                }
            } catch (reason) {

            }
        },
        // Save Product API
        async saveProductApi(params) {
            return new Promise(async (resolve, reject) => {
                let response = await this.$http.post('mobile/orders/items', params)
                try{
                    if (response.data.status_id == 1) {
                        resolve(response.data)
                    } else {
                        reject(response.data.reason)
                        this.$swal({
                            imageUrl:'https://cdn.digicollect.com/cdn/posv2/dogwithscarf.svg',
                            title: 'Combo Failure',
                            text: response.data.reason,
                            showConfirmButton:false,
                            timer: 1000
                        })
                    }
                }
                catch(error){
                    console.log(error.data)
                    this.$toasted.global.error(error.response.data.reason)
                }
            })
        },
        // Fast Checkout Option
        async fastCheckoutProduct(){
            try {
                const result = []
                this.combo_products.forEach((el,i)=>{
                    if(el.length){
                        result.push(el)
                    }
                })
                this.combo_products = result
                let params = {
                    order_id:this.order_index,
                    product_id:this.comboDetails._id,
                    addons:[],
                    quantity:this.comboDetails.selected_quantity,
                    course:this.selectedCourse,
                    note:this.note,
                    is_customizable:true,
                    is_combo:true,
                    combo_products:this.combo_products,
                    web:true,
                }
                let res = await this.saveProductApi(params)
                if(res.status_id == 1){
                    this.$emit('cSelectCombo')
                    this.$emit('checkoutfastProduct')
                    this.product_list = []
                    this.selected_product = []
                    this.selected_product_ids = []
                    this.combo_products = []
                    this.$modal.hide(this.modal_name);
                }
            } catch (reason) {

            }
        },
        // Product has Variant
        showSelectCombo(){
            this.isVariant = false
            this.productDetails = ''
        },
        // Initially Variant Select
        variantUpdate(details,aindex){
            this.isVariant = false
            this.combo_products[aindex].push(details)
            this.selected_product_ids.push(details.product_id)
            this.product_list.forEach((el,i)=>{
                el.forEach((el1,eli)=>{
                    if(el1.alternative_id == details.alternative_id){
                        el1.selected_quantity = el1.selected_quantity + 1
                        el1.product_list.forEach((newel,index)=>{
                            if(newel._id == details.product_id){
                                newel.selected_quantity = newel.selected_quantity + 1
                                newel.variants.forEach((v,vi)=>{
                                    if(v.variant_id == details.variant_id){
                                        v.quantity++
                                    }
                                })
                            }
                        })
                    }
                })
            })
            this.product_list[aindex].forEach((p,index)=>{
                if(p.alternative_id != details.alternative_id){
                    p.enabled = true
                }
            })
            this.expandAndCloseProduct(aindex)
            setTimeout(() => {
                this.expandAndCloseProduct(aindex)
            }, 20);
            this.productDetails = ''
        },
        // Update Multiple Variants
        UpdateBasedOnMultiple(details){
            this.combo_products[details.ai].forEach((el,i)=>{
                details.variants.forEach((v,vi)=>{
                    if(el.variant_id == v.variant_id){
                        el.quantity = v.quantity
                    }else if(el.quantity > 0){
                        this.combo_products[details.ai].push(v)
                    }
                })
            })
            const result = [];
            const map = new Map();
            for (const item of this.combo_products[details.ai]) {
                if(!map.has(item.variant_id)){
                    map.set(item.variant_id, true);    // set any value to Map
                    result.push({
                        variant_id: item.variant_id,
                        product_id: item.product_id,
                        quantity:item.quantity,
                        alternative_id:item.alternative_id
                    });
                }
            }
            result.forEach((el,i)=>{
                if(el.quantity < 1){
                    result.splice(i,1)
                }
            })
            this.combo_products[details.ai] = result
            this.selected_product_ids.push(details.product_id)
            this.product_list.forEach((el,i)=>{
                el.forEach((el1,eli)=>{
                    el1.product_list.forEach((newel,index)=>{
                        if(newel._id == details.product_id){
                            let sum = 0
                            let allvalues = []
                            newel.variants.forEach((v,vi)=>{
                                allvalues.push(v.quantity)
                            })
                            for (var i = 0; i < allvalues.length; i++) {
                                if(allvalues[i] == ''){
                                    allvalues[i] = 0
                                    sum += allvalues[i];
                                }else{
                                    sum += allvalues[i];
                                }
                            }
                            newel.selected_quantity = sum
                            newel.variants.forEach((v,vi)=>{
                                details.variants.forEach((dv,dvi)=>{
                                    if(v.variant_id == dv.variant_id){
                                        v.quantity = dv.quantity
                                    }
                                })
                            })
                        }
                    })
                })
            })
            let sum1 = 0
            let allvalues1 = []
            this.product_list.forEach((el,i)=>{
                el.forEach((el1,eli)=>{
                    if(el1.alternative_id == details.alternative_id){
                        el1.product_list.forEach((product,product_index)=>{
                            allvalues1.push(product.selected_quantity)
                        })
                    }
                })
            })
            for (var i = 0; i < allvalues1.length; i++) {
                sum1 += allvalues1[i];
            }
            this.product_list.forEach((el,i)=>{
                el.forEach((el1,eli)=>{
                    if(el1.alternative_id == details.alternative_id){
                        el1.selected_quantity = sum1
                    }
                })
            })
            this.expandAndCloseProduct(details.ai)
            setTimeout(() => {
                this.expandAndCloseProduct(details.ai)
            }, 20);
            this.variantDetails = ''
        },
        // Deselect All
        deselectAll(){
            this.selected_product_ids = []
            this.product_list.forEach((el,i)=>{
                el.forEach((el1,eli)=>{
                    el1.selected_quantity = 0
                    el1.show = true
                    el1.enabled = false
                    el1.product_list.forEach((newel,index)=>{
                        newel.selected_quantity = 0
                        if(newel.hasMultipleVariants){
                            newel.variants.forEach((v,vi)=>{
                                v['quantity'] = 0
                            })
                        }
                    })
                })
            })
            this.product_list.forEach((el,i)=>{
                el.forEach((el1,eli)=>{
                    this.combo_products.push([])
                })
            })
        },
        // Incerase Qty
        increaseProductQty(){
            if(this.comboDetails.selected_quantity < this.comboDetails.product_quantity){
                this.comboDetails.selected_quantity++
                this.total = this.comboDetails.product_price * this.comboDetails.selected_quantity
            }
        },
        productQty(){
            if(this.comboDetails.selected_quantity != ''){
                if(this.comboDetails.selected_quantity < this.comboDetails.product_quantity){
                    this.total = this.comboDetails.product_price * this.comboDetails.selected_quantity
                }else{
                    this.comboDetails.selected_quantity = this.comboDetails.product_quantity
                    this.total = this.comboDetails.product_price * this.comboDetails.selected_quantity
                }
            }else{
                this.comboDetails.selected_quantity = 1
                this.total = this.comboDetails.product_price * this.comboDetails.selected_quantity
            }
        },
        decreaseProductQty(){
            if(this.comboDetails.selected_quantity > 1){
                this.comboDetails.selected_quantity--
                this.total = this.comboDetails.product_price * this.comboDetails.selected_quantity
            }
        },
        alterNativeShow(value,index,ai){
            if(value){
                this.product_list[index][ai].show  = false
                this.expandAndCloseProduct(index)
                setTimeout(() => {
                    this.expandAndCloseProduct(index)
                }, 20);
            }else{
                this.product_list[index][ai].show  = true
                this.expandAndCloseProduct(index)
                setTimeout(() => {
                    this.expandAndCloseProduct(index)
                }, 20);
            }

        }
    },
    computed:{
        loggedInUser() {
            return this.$store.getters.loggedInUser;
        }
    },
    mounted(){
        this.getDetails()
        this.total = this.comboDetails.product_price
        this.safariTrue = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    },
}
</script>
<style scoped>
.unavailable-addons {
    opacity: 0.6 !important;
    pointer-events: none;
    position: relative;
    vertical-align: middle;
    top: 28%;
    bottom: 20px;
    left: 50%;
    transform: translate(-50%, -50%);
    color: red;
    font-weight: 600;
    height: 26px;
    line-height: 26px;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.88);
    text-align: center;
}
.whiteSpace{
    white-space: nowrap;
}
.addon-col{
    padding-left: 8px;
    margin-top: 2px;
    padding-right: 8px;
}
.avatar-addons {
    font-size: 50px !important;
    line-height: 50px;
    background-color: transparent!important;
}
.avatar-addons {
    width: 50px;
    min-width: 50px;
    height: 50px;
    line-height: 50px;
    -webkit-border-radius: 50%;
    border-radius: 50%;
    font-weight: 500;
    text-align: center;
    color: #ffffff !important;
    background-color: transparent!important;
}
.word-break{
    word-break: break-word;
}
.variant-addon{
    cursor: pointer;
    width: 120px;
    height: 88px;
    text-align: center;
    border-radius: 7px;
    padding-top: 17px;
    box-shadow: 0px 5.5px 17px 0px rgba(0, 0, 0, 0.16);
}
.variant-addon-img{
    cursor: pointer;
    width: 174px;
    height: 95px;
    text-align: center;
    border-radius: 7px;
    padding-top: 5px;
    -webkit-box-shadow: 0px 5.5px 17px 0px rgba(0, 0, 0, 0.16);
    box-shadow: 0px 5.5px 17px 0px rgba(0, 0, 0, 0.16);
}
.selected_variant{
    background-color: #00448b;
    color: #fff;
}
.addon-border{
  position: relative;
  bottom: 11px;
  border-bottom: 0.1px solid #b2b2b3;
}
.collapse-addon{
    position: relative;
    display: block;
    float: right;
    cursor: pointer;
}
.v-modal-layout{
    overflow: initial !important;
}
.addon-main{
    width: 121px;
    margin-left: 7px;
}
.addon-note{
    height: 96px!important;
    border: 1px solid #b2b2b3!important;
    border-radius: 7px!important;
    padding: 10px 6px!important;
}
.addon-quantity{
    position: relative;
    border-bottom-left-radius: 7px;
    border-bottom-right-radius: 7px;
}
.w-52{
    width: 62px !important;
}
.total-addon{
    box-shadow: 0 2px 4px 0 rgba(162, 141, 141, 0.16);
    background-image: linear-gradient(97deg, #0086f8 20%, #005dae 99%);
    border-radius: 7px;
}
.add-custom-addon{
    width: 75px !important;
    color: #fff !important;
    border-radius: 8px!important;
    margin-left: 10px;
    margin-right: 10px;
    border: 1px solid #fff!important;
}
.bg-green-image{
    background-image: linear-gradient(83deg, #1a9347 8%, #1ca04d 53%, #20b256 93%);
}
.bg-blue-image{
    background-image: linear-gradient(92deg, #005dae 0%, #005dae 103%);
}
.bg-orange-image{
    background-image: linear-gradient(315deg, #fccb4a -30%, #f0712a 48%);
}
.bg-orange-image:focus{
    outline: 0px auto -webkit-focus-ring-color!important;
    color: #303031!important;
}
.b-r-7{
    border-radius: 7px;
}
.w-30{
    width: 28px;
}
.rotateIcon{
    transform: rotate(180deg);
}
.iconRotate{
    transform: rotate(0deg) !important;
}
.content-center{
    margin: 0 auto;
    position: relative;
    bottom: 12px;
}
.preferences{
    border-radius: 18px;
    border: solid 1px #b2b2b3;
    padding: 5px 10px 5px 10px;
    display: inline-block;
    margin-left: 4px;
}
.PointerEventsNone{
    pointer-events: none !important;
}
.vue-avatar--wrapper.product-avatar span{
    overflow-wrap:anywhere !important;
    padding:2px !important;;
}
</style>